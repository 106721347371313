document.addEventListener("turbolinks:load", function() {
    const tableElement = document.querySelector('#unpaid-bank-balance-table');
  
    if (tableElement) {
      if ($.fn.DataTable.isDataTable(tableElement)) {
        $(tableElement).DataTable().clear().destroy();
      }
  
      $(tableElement).DataTable({
        processing: true,
        serverSide: true,
        ajax: {
          url: $(tableElement).data('source'),
          data: function(d) {
            d.proceeds_paid_false_or_dealer_incentives_false = true;
          }
        },
        pagingType: "full_numbers",
        columns: [
          { data: "customer_name" },
          { data: "vehicle_cs_no" },
          { data: "financed" },
          { data: "bank" },
          { data: "proceeds_paid" },
          { data: "orcr" },    // New ORCR column
          { data: "aging" }    // Aging column
        ],
        columnDefs: [
          {
            targets: "_all",
            className: 'px-6 py-4 whitespace-no-wrap border-b text-sm leading-5 text-gray-500'
          }
        ],
        responsive: true,
        rowCallback: function(row, data) {
          // Update indices if necessary
          // Column indices (0-based):
          // 0: customer_name
          // 1: vehicle_cs_no
          // 2: financed
          // 3: date_of_released
          // 4: bank
          // 5: proceeds_paid
          // 6: email_date
          // 7: status
          // 8: orcr
          // 9: aging
  
          // Update proceeds_paid cell styling
          var proceedsPaidCell = $('td:eq(4)', row);
          if (data.proceeds_paid === "false") {
            proceedsPaidCell.css('background-color', 'rgb(254 226 226 / var(--tw-bg-opacity))');
            proceedsPaidCell.css('color', 'rgb(153 27 27 / var(--tw-text-opacity))');
            proceedsPaidCell.html('Pending');
          } else if (data.proceeds_paid === "true") {
            proceedsPaidCell.css('background-color', 'rgb(220 252 231 / var(--tw-bg-opacity))');
            proceedsPaidCell.css('color', 'rgb(22 101 52 / var(--tw-text-opacity))');
            proceedsPaidCell.html('Paid');
          }
  
          // Optionally, style the ORCR column based on its value
          var orcrCell = $('td:eq(5)', row);
          if (data.orcr === "True") {
            orcrCell.css('background-color', 'rgb(220 252 231 / var(--tw-bg-opacity))');
            orcrCell.css('color', 'rgb(22 101 52 / var(--tw-text-opacity))');
          } else {
            orcrCell.css('background-color', 'rgb(254 226 226 / var(--tw-bg-opacity))');
            orcrCell.css('color', 'rgb(153 27 27 / var(--tw-text-opacity))');
          }
        },
        dom: 'Bfrtip',
        stateSave: true,
        buttons: [
          { extend: 'colvis', className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50' },
          'copy',
          'pageLength'
        ]
      });
    }
  });
  
  
  document.addEventListener("turbolinks:before-cache", function() {
    const tableElement = document.querySelector('#unpaid-bank-balance-table');
    if (tableElement && $.fn.DataTable.isDataTable(tableElement)) {
      $(tableElement).DataTable().clear().destroy();
    }
  });
  

  document.addEventListener("turbolinks:load", function() {
    const tableElement = document.querySelector('#for-approval-table');
  
    if (tableElement) {
      if ($.fn.DataTable.isDataTable(tableElement)) {
        $(tableElement).DataTable().clear().destroy();
      }
  
      $(tableElement).DataTable({
        processing: true,
        serverSide: true,
        ajax: {
          url: $(tableElement).data('source'),
          data: function(d) {
            d.ok_to_invoice_false_or_ok_to_release_false = true;
          }
        },
        pagingType: "full_numbers",
        columns: [
          { data: "customer_name" },
          { data: "vehicle_cs_no" },
          { data: "ok_to_invoice" },
          { data: "ok_to_release" },
          { data: "date_of_release" }
        ],
        columnDefs: [
          {
            targets: "_all",
            className: 'px-6 py-4 whitespace-no-wrap border-b text-sm leading-5 text-gray-500'
          }
        ],
        responsive: true,
        "rowCallback": function(row, data) {
          if (data.ok_to_invoice === "No") {
            $('td:eq(2)', row).css('background-color', 'rgb(254 226 226 / var(--tw-bg-opacity))');
            $('td:eq(2)', row).css('color', 'rgb(153 27 27 / var(--tw-text-opacity))');
          } else if (data.ok_to_invoice === "Yes") {
            $('td:eq(2)', row).css('background-color', 'rgb(220 252 231 / var(--tw-bg-opacity))');
            $('td:eq(2)', row).css('color', 'rgb(22 101 52 / var(--tw-text-opacity))');
          }
  
          if (data.ok_to_release === "No") {
            $('td:eq(3)', row).css('background-color', 'rgb(254 226 226 / var(--tw-bg-opacity))');
            $('td:eq(3)', row).css('color', 'rgb(153 27 27 / var(--tw-text-opacity))');
          } else if (data.ok_to_release === "Yes") {
            $('td:eq(3)', row).css('background-color', 'rgb(220 252 231 / var(--tw-bg-opacity))');
            $('td:eq(3)', row).css('color', 'rgb(22 101 52 / var(--tw-text-opacity))');
          }
        },
        dom: 'Bfrtip',
        stateSave: true,
        buttons: [
          { extend: 'colvis', className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50' },
          'copy',
          'pageLength'
        ]
      });
    }
  });
  
  // Destroy DataTable before TurboLinks caches the page
  document.addEventListener("turbolinks:before-cache", function() {
    const tableElement = document.querySelector('#for-approval-table');
    if (tableElement && $.fn.DataTable.isDataTable(tableElement)) {
      $(tableElement).DataTable().clear().destroy();
    }
  });
  
  document.addEventListener("DOMContentLoaded", function() {
    if (!firebase.messaging.isSupported()) {
      console.warn("FCM is not supported on this browser.");
      return;
    }
  
    const messaging = firebase.messaging();
  
    function requestPermission() {
      return Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
          return messaging.getToken({ vapidKey: "BG3dINE41Vfn-jUSbgPXaChDi5g9i7Xv-UuoGSxiYS_56ZAS1bJgUkzgZHFxRRI_o__fMU0Qc5Qb64BbDc_U2hQ" });
        } else {
          console.log("Notification permission denied.");
        }
      });
    }
  
    function sendTokenToServer(token) {
      fetch("/notification_tokens", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("[name=csrf-token]").content
        },
        body: JSON.stringify({ notification_token: { token: token, platform: "fcm" } })
      }).then(response => {
        if (!response.ok) {
          console.error("Failed to register token with server");
        }
      });
    }
  
    // Request notification permission and get token
    requestPermission().then(token => {
      if (token) {
        sendTokenToServer(token);
      }
    });
  
    // Update token if refreshed
    messaging.onTokenRefresh(() => {
      messaging.getToken().then(refreshedToken => {
        console.log("Token refreshed.");
        sendTokenToServer(refreshedToken);
      });
    });
  
    // Handle incoming messages
    messaging.onMessage(payload => {
      console.log("Message received:", payload);
      // You could show a notification directly here if needed
    });
  });
  